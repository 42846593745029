import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import MobileMenu from "components/MobileMenu";
import { Button, LanguageSwitcher } from "components/ui";
import { LogoIcon } from "icons";

const HeaderLoggedOut = () => (
  <header className="container flex h-16 items-center justify-between lg:h-24 lg:justify-start">
    <Link to="/">
      <LogoIcon className="w-28 text-blue-700 lg:w-48" />
    </Link>

    <div className="hidden w-full items-center lg:flex">
      <nav className="ml-15 ">
        <ul className="flex">
          {/* <li className="mr-8">
            <Link className="text-blue-700" to="/">
              <FormattedMessage id="header.offerings" />
            </Link>
          </li> */}
          <li className="mr-8">
            <Link className="text-grey-100 hover:text-blue-700" to="/how-it-works">
              <FormattedMessage id="header.howItWorks" />
            </Link>
          </li>
          <li className="">
            <Link className="text-grey-100 hover:text-blue-700" to="/welcome">
              <FormattedMessage id="header.joinEnviago" />
            </Link>
          </li>
        </ul>
      </nav>
      <LanguageSwitcher className="ml-15" />
      <div className="ml-auto flex">
        <Button className="mr-2 w-32 px-0" component="Link" variant="text" to="/sign-in">
          <FormattedMessage id="header.signIn" />
        </Button>
        <Button className="w-32 px-0" component="Link" to="sign-up">
          <FormattedMessage id="header.signUp" />
        </Button>
      </div>
    </div>
    <LanguageSwitcher className="ml-auto mr-4 lg:hidden" />
    <MobileMenu className="lg:hidden" />
  </header>
);

export default HeaderLoggedOut;
