import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { InstagramIcon, TikTokIcon, XIcon, LogoIcon } from "icons";

import FooterIconLink from "./FooterIconLink";

const FooterLoggedOut = () => (
  <footer className="bg-blue-700 py-10 lg:pb-15 lg:pt-11">
    <div className="container">
      <div className="-mt-10 mb-10 h-px w-full bg-white/20" />
      <div className="flex flex-col lg:flex-row lg:items-center">
        <LogoIcon className="mx-auto mb-5 w-40 text-white lg:mb-0 lg:ml-0" />
        <ul className="mx-auto mb-8 flex w-full max-w-lg flex-col justify-between gap-4 text-center md:flex-row md:gap-0 lg:mb-0">
          <li>
            <a
              className="text-white hover:underline"
              href="https://enviago.zendesk.com/hc/en-us"
              target="_blank"
            >
              <FormattedMessage id="link.support" />
            </a>
          </li>
          <li>
            <a className="text-white hover:underline" href="/privacy-policy" target="_blank">
              <FormattedMessage id="link.privacy" />
            </a>
          </li>
          <li>
            <Link className="text-white hover:underline" to="/terms-of-service" target="_blank">
              <FormattedMessage id="link.terms" />
            </Link>
          </li>
          <li>
            <Link className="text-white hover:underline" to="/dmca" target="_blank">
              <FormattedMessage id="link.dmca" />
            </Link>
          </li>
        </ul>
        <ul className="mx-auto flex lg:mr-0">
          <li className="mr-6">
            <FooterIconLink href="https://instagram.com/enviago_oficial">
              <InstagramIcon />
            </FooterIconLink>
          </li>
          <li className="mr-6">
            <FooterIconLink href="https://www.tiktok.com/@enviago">
              <TikTokIcon />
            </FooterIconLink>
          </li>

          <li className="">
            <FooterIconLink href="https://x.com/enviago_oficial">
              <XIcon />
            </FooterIconLink>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default FooterLoggedOut;
